import { useField } from "formik";
import React, { useMemo } from "react";

/* Components */
import { Text } from "@components/Typography";
import { Label } from "@components/shared";

/* CSS */
import { textareaStyles } from "./styles.css";

type Props = {
  children?: React.ReactNode;
  name: string;
  label?: string;
  wrapperClassName?: string;
  inputWrapperClassName?: string;
  errorBeforeTouch?: boolean;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const {
      label,
      wrapperClassName = "",
      errorBeforeTouch = false,
      inputWrapperClassName = "",
      className,
      ...rest
    } = props;

    const [field, meta] = useField(rest);
    const error = useMemo(() => meta.error || "", [meta.error]);

    return (
      <fieldset className={`flex flex-col gap-4 w-full ${wrapperClassName}`}>
        <Label label={label} htmlFor={field.name} />
        <div className={`flex w-full flex-col gap-2 ${inputWrapperClassName}`}>
          <textarea
            rows={rest.rows || 5}
            className={`${textareaStyles} ${className}`}
            {...field}
            ref={ref}
          />
          <Text className="!text-red-500">
            {errorBeforeTouch ? error : meta.touched && error}
          </Text>
        </div>
      </fieldset>
    );
  },
);

Textarea.displayName = "Textarea";
