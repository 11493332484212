import { styled } from "@styles/stitches";

export const StyledInput = styled("input", {
  padding: "0.5rem 1rem",
  borderRadius: "8px",
  width: "100%",
  background: "transparent",
  outline: "1px solid transparent",
  transition: "border-color 300ms ease-in",

  '&[type="file"]': {
    opacity: 0,
    display: "none",
  },

  "&:read-only": {
    background: "$neutral-gray",
    color: "$neutral-white",
    pointerEvents: "none",

    "&:focus": {
      outline: "none",
    },
  },

  "&:placeholder": {
    color: "$grey",
  },

  "&[type='file']": {
    opacity: 1,
  },

  variants: {
    variant: {
      underlined: {
        borderRadius: 0,
        border: "none",
        borderBottom: "1px solid $grey",
        "&:focus": {
          outline: "none",
          borderBottom: "$primary 1px solid",
        },
      },
      filled: {
        color: "$primary-text",
        border: "1px solid $primary-text",
        "&:placeholder": {
          color: "$grey",
        },

        "&:focus": {
          borderColor: "$primary",
        },
      },
    },
  },
});
